import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const SwalModal = config => MySwal.fire({
  showConfirmButton: false,
  showCloseButton: true,
  focusConfirm: false,
  closeButtonHtml: '<i class="fas fa-times red-text"></i>',
  ...config,
  customClass: {
    container: 'tel-swal',
    ...config.customClass,
  },
});
