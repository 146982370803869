import { useEffect } from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { useServiciosFijos } from '~/store/Query/api-fijo';
import productHelper from '~/helpers/product';
import { SwalModal } from '~/store/Modals/sweet';
import { currencyFormat } from '~/helpers/formatter';
import { OFERTA_RENOVACION } from '~/store/States/global';

export const Modal = config => SwalModal({
  closeButtonHtml: '<i class="fas fa-times red-text modal-close"></i>',
  ...config,
});

const fijoState = hookstate({
  texto: {
    9: {
      precioEtiqueta: 'Precio del plan',
      precioPrefijo: '',
      precioSufijo: ' AL MES',
      seleccionar: 'Seleccionar',
      detalle: 'Ver detalle',
    },
    10: {
      precioEtiqueta: '',
      precioPrefijo: '',
      precioSufijo: '',
      seleccionar: 'Seleccionar',
      detalle: 'Ver detalle',
    },
    aviso: 'Debes elegir un plan antes de seleccionar uno o más servicios.',
    total_prefijo: 'Total',
    total_sufijo: ' /mes',
    boton_continuar: 'Continuar',
    form_titulo: 'Hazlo fácil, seguro y ágil',
    form_subtitulo: 'Ahora puedes contratar servicios fijos sin salir de casa. Envíanos tus datos y pronto de contactaremos.',
    form_datos: 'Datos de contratación',
    form_resumen: 'Resumen de contratación',
    form_boton: 'Enviar solicitud',
    modal_titulo: 'Gracias por ingresar tus datos',
    modal_texto: 'Muy pronto te estaremos contactando',
    aviso_legal: 'Aplican restricciones.',
  },
});

const planSelected = hookstate([]);

const addonsSelected = hookstate({});

const totalSelected = hookstate(['0', '00']);

export const categoriaFijoState = hookstate();

export const useFijoState = () => {
  const config = useServiciosFijos();
  useEffect(() => {
    if (config.data?.ok) {
      const info = config.data.body.data;
      const data = info.texto;
      fijoState.merge({
        ...info,
        texto: {
          ...data,
          9: {
            precioEtiqueta: data.plan_precio_etiqueta,
            precioPrefijo: data.plan_precio_prefijo,
            precioSufijo: data.plan_precio_sufijo,
            seleccionar: data.plan_seleccionar,
            detalle: data.plan_detalle,
          },
          10: {
            precioEtiqueta: data.addon_precio_etiqueta,
            precioPrefijo: data.addon_precio_prefijo,
            precioSufijo: data.addon_precio_sufijo,
            seleccionar: data.addon_seleccionar,
            detalle: data.addon_detalle,
          },
        },
      });
    }
  }, [config.data]);
  return useHookstate(fijoState);
};

export const usePlanSelected = () => useHookstate(planSelected);
export const useAddonsSelected = () => useHookstate(addonsSelected);
export const useTotalSelected = () => useHookstate(totalSelected);

export const productInfo = (item) => {
  const name = item.locales[process.env.NEXT_PUBLIC_LOCALE].name.replace(/<br>/, '\n').replace(/<br\/>/, '\n').replace(/\\n/, '\n');
  const simplename = name.replace(/\\n/g, '');
  const offer = item.pricing[process.env.NEXT_PUBLIC_CURRENCY].offer_price / 100;
  const attrMap = productHelper.convertAttributes(item.attributes);
  const code = attrMap['attr-conf-friendly-url'] || item.locales[process.env.NEXT_PUBLIC_LOCALE].seo.name || item.part_number;
  const format = currencyFormat(offer, 1);
  const isOfertaNew = attrMap['attr-conf-tipo-oferta']?.toUpperCase() !== OFERTA_RENOVACION;
  return {
    name,
    simplename,
    offer,
    code,
    format,
    isOfertaNew,
  };
};
